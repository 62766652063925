import React, { useLayoutEffect, useRef } from 'react'
import { motion} from "framer-motion"
import { Link, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
const Section1 = () => {

    const { t, i18n } = useTranslation();


    const aboutSection = useRef()
    const ourbrandsSection = useRef()


    let location = useLocation();


    useLayoutEffect(() => {
      window.scrollTo(0, 0);

    
      if(location.hash === '#about'){
        window.scrollTo(0,aboutSection.current.offsetTop-160)
    }

    if(location.hash === '#ourbrands'){
        window.scrollTo(0,ourbrandsSection.current.offsetTop-160)
    }



      
    }, [location])




    
    const list = {
        visible: {
          opacity: 1,
    
          transition: {
            when: "beforeChildren",
            staggerChildren: 0.2,
          },
        },
        hidden: { opacity: 0 },
    
      }
    
      const itemText = { visible: { opacity: 1 ,transition:{duration:1}}, hidden: { opacity: 0 }, }
    return (
        <div className=' w-full  px-12rem '>

            <motion.div id='about' ref={aboutSection} initial="hidden" whileInView="visible" variants={list} className='  w-full flex items-center lg:space-x-10  my-20 flex-col lg:flex-row'>
                <motion.div  className=' bg-[#0158A9] flex flex-col p-10 lg:p-20 w-full lg:w-auto'>
                    <h6 className='text-[#82868A] mb-5 '>{t('about-us-title')}</h6>
                    <h6 className='text-white text-4xl lg:text-5xl font-bold'>{t('about-us-title-1')}</h6>
                    <h6 className='text-white text-4xl lg:text-5xl font-bold'>{t('about-us-title-2')}</h6>
                    <h6 className='text-white text-4xl lg:text-5xl font-bold'>{t('about-us-title-3')}</h6>
                    <h6 className='text-white text-4xl lg:text-5xl font-bold'>{t('about-us-title-4')}</h6>
                    <h6 className='text-white text-4xl lg:text-5xl font-bold'>{t('about-us-title-5')}</h6>
                </motion.div>
                <motion.div variants={itemText} className='w-full h-full  flex  flex-col'>
                    <div className='flex my-10'>
                        <h6 className=' text-4xl font-bold'>{t('about-us-title-1-1')} <span  className='text-[#0158A9] text-4xl font-bold'> {t('about-us-title-1-2')} </span></h6>
                    </div>
                    <p className='text-[#82868A]'>
                    {t('about-us-title-1-3')}
                    </p>
                    <p className='text-[#82868A]'>
                    {t('about-us-title-1-4')}
                    </p>
                </motion.div>
            </motion.div>




            <motion.div id='ourbrands' ref={ourbrandsSection} initial="hidden" whileInView="visible" variants={list} className='grid grid-cols-2 my-20'>
                <a className='flex' href='https://akanaribs.com/'><motion.div variants={itemText} className='flex justify-center items-center border-r border-black p-10'>
                    <img src='image/references/1.svg' className='' />
                </motion.div>
                </a>
                <a className='flex' href='https://seavel.vercel.app/'><motion.div variants={itemText} className='flex justify-center items-center p-10'>
                    <img src='image/references/2.svg' className='' />
                </motion.div>
                </a>
            </motion.div>



            <motion.div initial="hidden" whileInView="visible" variants={list} className='h-10 w-full bg-[#F0F0F0] my-20'></motion.div>

            {/* <motion.div initial="hidden" whileInView="visible" variants={list} className='grid grid-cols-1 gap-10 my-20  md:grid-cols-2 '>
                <motion.div variants={itemText} className='flex flex-col space-y-5  lg: pr-10 border-none lg:border-r'>
                    <h6 className='text-4xl font-bold'>Vizyonumuz</h6>
                    <p className='text-[#82868A]'>Gönül rahatlığı ile güvenli bir yolculuk için ihtiyaç duyulan yenilikçi çözümleri sunarak, marin sektöründe adından söz ettiren bir marka olmak.</p>
                </motion.div>


                <motion.div variants={itemText} className='flex flex-col space-y-5 border-r lg:pr-10 border-none lg:border-r'>
                    <h6 className='text-4xl font-bold'>Misyonumuz</h6>
                    <p className='text-[#82868A]'>Maviliğin sınırsız özgürlüğünü herkesle paylaşmak, özgün deniz araçları ile unutulmaz deniz deneyimleri sunmak için buradayız. Her üretimde güç, konfor ve güvenliği ön planda tutarak, deniz tutkunlarının hayallerindeki yolculuğu gerçeğe dönüştürmek için çalışıyoruz.</p>
                </motion.div>



            </motion.div> */}

        </div>
    )
}

export default Section1