import React from 'react'
import { motion } from "framer-motion"

import { Link} from 'react-router-dom';

import { useTranslation } from 'react-i18next';
const Footer = () => {

  const { t, i18n } = useTranslation();


  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    hidden: { opacity: 0 },

  }

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (
    <motion.div initial="hidden" whileInView="visible" variants={list} className=' w-full   px-6rem z-50 bg-[#0158A9] py-10'>
    <div className='w-full h-full  flex flex-col space-y-5'>
      <motion.div variants={itemText} className='flex justify-between items-center'>
        <Link to={'/'}><img src='image/logo.svg' /></Link>

        <Link to={'/'}><img className='h-10 w-10' src='image/hook.svg' /></Link>
      </motion.div>
      <div className='flex space-x-0 lg:space-x-5 flex-col lg:flex-row space-y-5 lg:space-y-0'>
        <motion.div variants={itemText} className=' h-32 w-full lg:w-[24rem] mr-10'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.5944226617507!2d28.83019471256254!3d40.9869567793611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa3feca109815%3A0xbbab8f6abfc174ba!2sEGS%20Business%20Park!5e0!3m2!1sen!2str!4v1714489436815!5m2!1sen!2str" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </motion.div>
        <motion.div variants={itemText} className='max-w-64 flex flex-col'>

          <h6 className='text-white font-bold text-xl'>{t('contact-title')}</h6>
          <a href='https://maps.app.goo.gl/3D76Uc816qwKCrko8' className='text-white'>Yeşilköy Mah. Atatürk Cad.EGS Business Park 12/8 Bakırköy İstanbul</a>
          <a href='tel:+496170961709' className='text-white'>0 212 259 15 17</a>
          <a href='mailto:info@runomar.com' className='text-white'>info@runomar.com</a>
        </motion.div>

        <motion.div variants={itemText} className='max-w-96 flex flex-col'>
          <h6 className='text-white font-bold text-xl'>{t('company-and')}</h6>
          <Link to={'/'}><a className='text-white'>{t('header-1')}</a></Link>
          <Link to={'/#about'}><a className='text-white'>{t('header-2')}</a></Link>
          <Link to={'/#ourbrands'}><a className='text-white'>{t('header-3')}</a></Link>
          <Link to={'/#contact'}><a className='text-white'>{t('header-4')}</a></Link>
        </motion.div>

      </div>


      <motion.div variants={itemText} className=' w-full  flex justify-between'>
        <p className='text-white'>{t('foooter-bottom')}</p>
        
      </motion.div>
    </div>
  </motion.div>
  )
}

export default Footer