import React, { useLayoutEffect, useRef } from 'react'

import { motion } from "framer-motion"
import Section1 from '../components/Section1'
import { Link, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';


import { useTranslation } from 'react-i18next';



const Home = () => {


  const { t, i18n } = useTranslation();

  const contact = useRef()


  let location = useLocation();


  useLayoutEffect(() => {



    if (location.hash === '#contact') {
      window.scrollTo(0, contact.current.offsetTop - 120)
    }


  }, [location])










  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    hidden: { opacity: 0 },

  }

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (
    <>
      <div className='h-screen w-full  overflow-hidden relative'>
        <video className='h-full w-full object-cover absolute z-0' id="video" autoPlay loop muted playsInline  >
          <source src="video/1.mp4" type="video/mp4" />


        </video>
        <div className='w-full h-full   relative z-10 px-6rem'>
          <div className='h-full w-full'>

            <div className='h-full w-full  flex  flex-col justify-center'>
              <h6 className='text-white font-bold text-5xl lg:text-7xl'>{t('banner-title-1')}</h6>
              <h6 className='text-white font-light  text-5xl lg:text-7xl'>{t('banner-title-2')}</h6>
              {/* <div className='mt-10'>
            <div className=' inline-block px-8 py-4 border border-white text-white text-sm'>DAHA FAZLASI</div>
          </div> */}

            </div>
          </div>
        </div>
      </div>



      <Section1 />


      {/* <motion.div initial="hidden" whileInView="visible" variants={list} className=' flex flex-col mt-20 bg-[#F0F0F0]'>
    <div className='px-12rem py-14'>
      <motion.div variants={itemText} className='flex mb-10'>
        <h6 className='text-black text-4xl lg:text-5xl font-bold'>BİZİ DAHA YAKINDAN TANIYIN</h6>
      </motion.div>

      <motion.p className='text-[#82868A]'>Sonsuz maviye olan tutkumuz, yeni keşiflere açılan yepyeni bir yolculuğun kapılarını aralıyor. 

Keşfedilecek yeni ufuklara seyahat ederken her model ve tasarımlarımız, deniz tutkusunu yansıtıyor. Her aşamada konfor ve güvenliği ön planda tutarak, malzeme seçiminden üretime kadar özenle çalışıyor, Müşterilerimize unutulmaz yolculuklar için benzersiz bir deneyim sunmayı hedefliyoruz.
.</motion.p>
    </div>
  </motion.div> */}


      <motion.div initial="hidden" whileInView="visible" variants={list} className='overflow-hidden w-full  '>
        <img src='image/banner2.jpg' />
      </motion.div>


      <div id='contact' ref={contact} className=' w-full  px-12rem my-20'>
        <div className='grid lg:grid-cols-12 gap-10 grid-cols-1'>

          <div className=' bg-[#0158A9] flex flex-col p-10 lg:h-96 lg:col-span-4'>
            <h6 className='text-white mb-5 font-bold text-2xl'>{t('contact-title')}</h6>
            <p className='text-white'>{t('contact-title-1')}</p>
          </div>



          <div className='  flex  flex-col flex-none h-full  lg:col-span-8 '>
            <div className='h-1 w-full bg-white'></div>

            <div className=' w-full gap-10 space-x-5 '>
              <div className='flex flex-col space-y-5'>
                <a href='https://maps.app.goo.gl/3D76Uc816qwKCrko8'>
                <div className='flex items-center border-b border-b-[#0158A9] space-x-5 hover:scale-105 transition-all duration-500'>
                  <div className='bg-[#0158A9] p-5 text-white min-w-28'>{t('contact-1')}</div>
                  <h5>Yeşilköy Mah. Atatürk Cad.EGS Business Park 12/8 Bakırköy İstanbul</h5>
                </div>
                </a>
                <a href='tel:+496170961709'>
                <div className='flex items-center border-b border-b-[#0158A9] space-x-5 hover:scale-105 transition-all duration-500'>
                <div className='bg-[#0158A9] p-5 text-white  min-w-28'>{t('contact-2')}</div>
                  <h5>0 212 259 15 17</h5>
                </div>
                </a>

                <a href='mailto:info@runomar.com'>
                <div className='flex items-center border-b border-b-[#0158A9] space-x-5 hover:scale-105 transition-all duration-500'>
                <div className='bg-[#0158A9] p-5 text-white  min-w-28'>{t('contact-3')}</div>
                  <h5>info@runomar.com</h5>
                </div>
                </a>

              </div>

              {/* <div className='flex flex-col flex-1 w-auto lg:w-80 space-y-5 '>
            <input placeholder='İsim' className='p-1 border-b' />
            <input placeholder='Telefon' className='p-1 border-b' />
            <textarea placeholder='Mesajınız' rows={5} className='p-1 border-b' />
          </div>
          <div className='flex flex-col flex-1 space-y-5'>
            <input placeholder='Soyadı' className='p-1 border-b' />
            <input placeholder='Mail' className='p-1 border-b' />
          </div> */}

            </div>

            {/* 
        <div className='mt-10'>
          <div className=' inline-block px-8 py-4 border border-[#0158A9] text-[#0158A9] text-sm'>GÖNDER</div>
        </div> */}

          </div>



        </div>
      </div>


      <Footer />
    </>
  )
}

export default Home