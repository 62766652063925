import { Link, Route, Routes } from 'react-router-dom';
import Headerr from './components/Headerr';
import logo from './logo.svg';
import Section1 from './components/Section1';
import { motion } from "framer-motion"
import Home from './pages/Home';
import { useState } from 'react';

function App() {

  const [popUp, setPopUp] = useState(true)
  const [languageE, setLanguageE] = useState('tr')

  console.log(languageE)

  const handleVideoClick = (event) => {
    event.stopPropagation();
    event.target.play();
  };

  return (

    <div className='3xl:container mx-auto w-full relative'>
      {popUp && <div  className='fixed h-screen w-full  z-[100] '>
        <div onClick={()=>setPopUp(false)} className='h-full w-full  flex justify-center backdrop-blur-sm relative items-center'>
          <div className=' w-3/6 lg:w-1/5 '>
            {
              languageE==='tr'?(
                <video   poster="image/mockup.png"   controls     onClick={handleVideoClick} className='h-full w-full object-cover '   >
                <source src="video/tr.mov" type="video/mp4" />
              </video>
              ):(
                <video poster="image/mockup-en.png"  controls   onClick={handleVideoClick} className='h-full w-full object-cover '   >
                <source src="video/en.mov" type="video/mp4" />
              </video>
              )
              
            }

          </div>
          <div className='absolute right-0 top-0  h-16 w-16 flex items-center'>
            <div className='relative h-full w-full  flex items-center justify-center'>
              <div className='h-1 w-10 bg-white absolute transform rotate-45'></div>
              <div className='h-1 w-10 bg-white absolute transform -rotate-45'></div>
            </div>
          </div>
        </div>
      </div>}
      <Headerr  popUp={popUp} setPopUp={setPopUp} languageE={languageE} setLanguageE={setLanguageE}/>
      <Routes>
        <Route path="/" element={<Home />} />

      </Routes>
    </div>
  );
}

export default App;
