import React, { useEffect, useState } from 'react'
import { motion, useScroll, useTransform, useViewportScroll } from "framer-motion"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';




const Headerr = ({popUp,setPopUp,language,setLanguageE}) => {

    const { t, i18n } = useTranslation();

    console.log(i18n.language)

    const changeLanguage = (language) => {
      i18n.changeLanguage(language);
      setPopUp(true)
      setLanguageE(language)
    };
   

    const { scrollY } = useViewportScroll()
    const { scrollYProgress } = useScroll();
    const [toggle, setToggle] = useState(false)



    
    const bgColor = useTransform(
        scrollY,
        [0, 2 * 16],
        ["rgba(0, 0, 0, 0)", "rgba(255, 255, 255, 1)"]
    );

    const iconColor = useTransform(
        scrollY,
        [0, 2 * 16],
        ['#FFFFFF', '#0158A9']
    );

    const textColor = useTransform(
        scrollY,
        [0, 2 * 16],
        ["rgba(255, 255, 255, 1)", "rgba(0, 0, 0, 1)"]
    );
    const textColorReverse = useTransform(
        scrollY,
        [0, 2 * 16],
        ["rgba(0, 0, 0, 1)", "rgba(255, 255, 255, 1)"]
    );


    const logoVisibility= useTransform(
        scrollY,
        [0, 2 * 16],
        [0, 1]
    );

    const logoVisibilityReverse= useTransform(
        scrollY,
        [0, 2 * 16],
        [1, 0]
    );
    return (
        <motion.div style={{ backgroundColor: bgColor }} className='h-24 w-full  fixed px-6rem z-50  transition-all duration-500'>
            <div className='w-full h-full  flex justify-between items-center'>
               <Link to={'/'} className='h-full w-[20rem]'>
                <div className='relative h-full '>
                <motion.img style={{opacity:logoVisibility}} className='cursor-pointer  w-56 h-full  top-0 left-0 absolute' src='image/logo-blue.svg '  />
                <motion.img style={{opacity:logoVisibilityReverse}} className='cursor-pointer  w-56 h-full  top-0 left-0 absolute' src='image/logo.svg'  />
                </div>
               </Link>
                <div className='w-full lg:flex items-center hidden'>
                    <div className='w-full h-full  flex items-center justify-center space-x-20'>
                        <motion.ul style={{ color: textColor }} className='flex justify-center h-14 items-center  text-white space-x-10'>
                            <Link to={'/'}>
                            <li className='group cursor-pointer'>
                                <h5 className='text-sm'>{t('header-1')}</h5>
                                <div className='h-px w-0 bg-[#6AA9E5] group-hover:w-full transition-all duration-500'></div>
                            </li>
                            </Link>
                            <Link to={'/#about'}>
                            <li className='group cursor-pointer'>
                                <h5 className='text-sm'>{t('header-2')}</h5>
                                <div className='h-px w-0 bg-[#6AA9E5] group-hover:w-full transition-all duration-500'></div>
                            </li>
                            </Link>
                            <Link to={'/#ourbrands'}>
                            <li className='group cursor-pointer'>
                                <h5 className='text-sm'>{t('header-3')}</h5>
                                <div className='h-px w-0 bg-[#6AA9E5] group-hover:w-full transition-all duration-500'></div>
                            </li>
                            </Link>
                            <Link to={'/#contact'}>
                            <li className='group cursor-pointer'>
                                <h5 className='text-sm'>{t('header-4')}</h5>
                                <div className='h-px w-0 bg-[#6AA9E5] group-hover:w-full transition-all duration-500'></div>
                            </li>
                            </Link>

                        </motion.ul>
                        <motion.div style={{ color: textColor }} className='text-white  '>
                            <div className=' flex space-x-1'>
                            <div className=' group'>
                            <button onClick={() => changeLanguage("tr")}>TR</button>
                            <div className='h-px w-0 bg-[#6AA9E5] group-hover:w-full transition-all duration-500'></div>
                            </div>
                            <div>-</div>
                            <div className=' group'>
                            <button onClick={() => changeLanguage("en")}>EN</button>
                            <div className='h-px w-0 bg-[#6AA9E5] group-hover:w-full transition-all duration-500'></div>
                            </div>
                            </div>
  
                        </motion.div>
                    </div>
                    {/* <div className=' h-full '>
                        <div className='h-full w-full  flex justify-center items-center'>
                           <Link to={'tel:+905323490081'}> <motion.div style={{ color: textColor, borderColor: textColor }} className='border border-white py-2 px-4 text-nowrap text-white'>
                                +90 532 349 00 81
                            </motion.div>
                            </Link>
                        </div>
                    </div> */}
                </div>



                <div onClick={() => setToggle(!toggle)} className='flex flex-col items-center justify-center relative cursor-pointer h-7 w-8 lg:hidden'>
                    <motion.div style={{ backgroundColor: textColor }} className={`h-1 w-8  absolute rounded-sm top-0 transition-all duration-500 ${toggle ? 'top-3' : 'top-0'} ${toggle ? 'rotate-45' : 'rotate-0'}`}></motion.div>
                    <motion.div style={{ backgroundColor: textColor }} className={`h-1 w-8  absolute rounded-sm top-3 transition-all duration-500 ${toggle ? 'translate-x-32' : 'translate-x-0'}`}></motion.div>
                    <motion.div style={{ backgroundColor: textColor }} className={`h-1 w-8  absolute rounded-sm transition-all duration-500  ${toggle ? 'top-3' : 'top-6'} ${toggle ? '-rotate-45' : 'rotate-0'}`} ></motion.div>
                </div>


            </div>


            <motion.div style={{ scaleX: scrollYProgress }} className={`h-1  w-full bg-[#0158A9] opacity-100 `}></motion.div>
            {
                toggle&&
            <motion.div       initial={{ opacity: 0,rotate:-10,radius:100}}
            animate={{ opacity: [0,0,1] ,rotate:0,radius:100}}
            exit={{ opacity: 0 ,rotate:0,radius:100}} className={`lg:hidden w-full  ${toggle ? 'h-[100vh]' : 'h-0vh'} bg-white absolute -z-10 top-0 left-0  transition-all duration-500  overflow-hidden `}>
                <motion.div style={{ backgroundColor: textColorReverse, color: textColor }} className={` ${toggle ? 'h-[100%]' : 'h-[0%]'} transition-all duration-500 overflow-hidden flex items-center flex-col justify-center ${toggle?'opacity-100':'opacity-0'}`}>
                    <Link onClick={()=>setToggle(false) } to={'/'}><motion.div className='cursor-pointer' >{t('header-1')}</motion.div></Link>
                    <Link onClick={()=>setToggle(false)} to={'/#about'}><motion.div className='cursor-pointer' >{t('header-2')}</motion.div></Link>
                    <Link onClick={()=>setToggle(false)} to={'/#ourbrands'}><motion.div className='cursor-pointer' >{t('header-3')}</motion.div></Link>
                    <Link onClick={()=>setToggle(false)} to={'/#contact'}><motion.div className='cursor-pointer' >{t('header-4')}</motion.div></Link>

                    <div className='flex space-x-2 mt-10'>
                        <div onClick={() =>{ changeLanguage("tr");setToggle(false)}}  className='cursor-pointer'>TR</div>
                        <div className='h-full w-px bg-white'></div>
                        <div onClick={() => { changeLanguage("en");setToggle(false)}}  className='cursor-pointer'>EN</div>
                    </div>

                    {/* <Link to={'tel:+905323490081'}> <motion.div style={{ color: textColor, borderColor: textColor }} className='border border-white py-2 px-4 text-nowrap text-white mt-10'>
                                +90 532 349 00 81
                            </motion.div>
                            </Link> */}
      

                </motion.div>

            </motion.div>
            }
        </motion.div>
    )
}

export default Headerr